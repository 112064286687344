<template>
  <div class="pay">
    <strong><span>￥</span>{{ totalAmount.toFixed(2) }}</strong>
    <p>支付剩余时间 {{ time }}</p>
    <van-radio-group v-model="radio">
      <van-cell-group v-for="(list, i) in pay" :key="i">
        <van-cell clickable @click="radio = i">
          <template #title>
            <img :src="list.icon" alt="" />
            <span>{{ list.title }}</span>
          </template>
          <template #right-icon>
            <van-radio :name="i" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="bottom">
      <van-button round color="#009A4D" @click="BankPay"
        >确认支付<span style="font-size: 12px">￥</span
        ><b>{{ totalAmount.toFixed(2) }}</b></van-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getOpenId } from "@/utils/auth";
import { getStrByteLength } from "@/utils/validate";
import Cookies from "js-cookie";
export default {
  name: "pay-for-the-order",
  computed: {
    ...mapState("user", ["userInfo", "bank", "taskList"]),
  },
  data() {
    return {
      radio: 0,
      pay: [
        {
          title: "微信支付",
          icon: require("@/assets/images/weixin_icon.png"),
        },
        // {
        //   title: "支付宝",
        //   icon: require("@/assets/images/zhifubao_icon.png"),
        // },
        // {
        //   title: "广州银行",
        //   icon: require("@/assets/images/gaungzhou_icon.png"),
        // },
        // {
        //   title: "通联支付",
        //   icon: require("@/assets/images/tonglian_icon.png"),
        // },
        // {
        //   title: "银联支付",
        //   icon: require("@/assets/images/yl_icon.png"),
        // },
      ],
      totalAmount: 0.0,
      order: [],
      time: "",
      id: "",
      goodsName: "", //商品名
      referenceAmount: 0, //团长参考总价
      totalAmount2: 0, //未抵扣积分价
      amountOfRefundablePoints: 0, //可返还积分金额
      info: "",
      seckill: false,
    };
  },
  created() {
    sessionStorage.removeItem(window.btoa("order"));
    window.callJS = this.callJS;

    // if (!getOpenId()) window.location.search = "";
  },

  mounted() {
    if (this.$route.query.totalDealNumber) {
      this.getOrder(); //总订单信息
    } else if (this.$route.query.dealNumber) {
      this.getOrder2(); //子订单信息
    }
  },
  beforeDestroy() {
    clearInterval(this.t);
  },

  methods: {
    async getOrder() {
      //totalDealNumber
      const data = await this.$API.order.TotalDealDetails({
        object: {
          // dealNumber: "DD20220823000310012",
          totalDealNumber: this.$route.query.totalDealNumber,
        },
      });

      this.order = data.data.data.dealDetailsRsps;
      this.order.forEach((order) => {
        this.totalAmount = order.totalAmount;
        order.orderCommodityListItems.forEach((goods) => {
          this.referenceAmount += goods.tradePrice * goods.quantity;
          if (goods.rebate)
            this.amountOfRefundablePoints += goods.actualPayment;

          // this.totalAmount2 += goods.sellingPrice * goods.quantity;
          //   this.goodsName += goods.commodityName + "　";
        });
      });
      // this.totalAmount2 = Math.round(this.totalAmount2 * 100) / 100;
      console.log(this.order);
      let size = getStrByteLength(
        this.order[0].orderCommodityListItems[0].commodityName
      );
      let maxSize = 120;
      let name = this.order[0].orderCommodityListItems[0].commodityName;
      if (size > maxSize) {
        let i = Math.ceil((size - maxSize) / 4) + 1;
        this.goodsName = name.slice(0, name.length - i) + "...";
      } else {
        this.goodsName = name;
      }
      this.order.forEach((order) => {
        let i = order.orderCommodityListItems.findIndex((item) => {
          return item.type === 2;
        });
        if (i > -1) this.seckill = true;
      });

      this.timeout(this.order[0].tradeTime);
      this.t = setInterval(() => {
        this.timeout(this.order[0].tradeTime);
      }, 1000);
    },
    async getOrder2() {
      //dealNumber
      const data = await this.$API.order.DealDetails({
        object: {
          // dealNumber: "DD20220823000310012",
          dealNumber: this.$route.query.dealNumber,
        },
      });
      this.order = data.data.data;
      this.order.orderCommodityListItems.forEach((goods) => {
        // this.goodsName += goods.commodityName + "　";
        this.referenceAmount += goods.tradePrice * goods.quantity;
        // this.totalAmount2 += goods.sellingPrice * goods.quantity;
        this.totalAmount += goods.actualPayment;
        if (goods.rebate) this.amountOfRefundablePoints += goods.actualPayment;
      });
      let size = getStrByteLength(
        this.order.orderCommodityListItems[0].commodityName
      );
      let maxSize = 120;
      let name = this.order.orderCommodityListItems[0].commodityName;
      if (size > maxSize) {
        let i = Math.ceil((size - maxSize) / 4) + 1;
        this.goodsName = name.slice(0, name.length - i) + "...";
      } else {
        this.goodsName = name;
      }

      // this.totalAmount2 = Math.round(this.totalAmount2 * 100) / 100;

      let i = this.order.orderCommodityListItems.findIndex((item) => {
        return item.type === 2;
      });
      if (i > -1) this.seckill = true;

      this.timeout(this.order.tradeTime);
      this.t = setInterval(() => {
        this.timeout(this.order.tradeTime);
      }, 1000);
    },

    timeout(val) {
      let EndTime = new Date(val); //截止时间
      let NowTime = new Date();
      if (this.seckill) {
        var t = 300000 - (NowTime.getTime() - EndTime.getTime());
      } else {
        var t = 1800000 - (NowTime.getTime() - EndTime.getTime());
      }

      // console.log(NowTime.getTime() - EndTime.getTime());
      let d = Math.floor(t / 1000 / 60 / 60 / 24);
      let h = Math.floor((t / 1000 / 60 / 60) % 24);
      let m = Math.floor((t / 1000 / 60) % 60);
      let s = Math.floor((t / 1000) % 60);
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      // this.time = `${d}天${h}小时${m}分${s}秒`;
      if (t < 0) {
        clearInterval(this.t);
        this.Toast.fail("支付订单超时关闭订单");
        this.$router.replace("/");
      }
      this.time = `${m}分${s}秒`;
    },
    async wxPay() {
      if (this.$store.state.App) {
        const token = Cookies.get("token_personal");
        let object = {
          type: 100,
          data: {
            // attach: "", //附加值
            dealNumber: this.$route.query.dealNumber
              ? this.$route.query.dealNumber
              : "", //订单编号
            // dealStatus: 8, //交易状态
            // payMethod: 11, //支付方式
            // payStatus: 1, //支付状态
            totalDealNumber: this.$route.query.totalDealNumber
              ? this.$route.query.totalDealNumber
              : "", //总订单号
            // transactionalNumber: "", //银行流水号
            userToken: token,
            totalAmount: Number(this.totalAmount),
            // description: this.goodsName,
          },
        };
        Android.callAndroid(JSON.stringify(object));
        return;
      }

      await this.$API.pay
        .insertWxOrder({
          object: {
            dealNumber: this.$route.query.totalDealNumber
              ? this.$route.query.totalDealNumber
              : this.$route.query.dealNumber,
            description: this.goodsName,
            openid: getOpenId(),
            totalAmount: Number(this.totalAmount),
          },
        })
        .then((res) => {
          if (res.data.code === "METHOD_ARGUMENT_NOT_VALID_EXCEPTION") {
            this.Toast.fail(res.data.msg);
            return;
          }
          this.info = res.data.data;
          this.arouseWeChatPayment();
          // this.id = res.data.data;
        })
        .catch((err) => {
          this.Toast.fail(err);
        });
    },
    //广银
    async BankPay() {
      if (this.$store.state.App) {
        const token = Cookies.get("token_personal");
        let object = {
          type: 100,
          data: {
            // attach: "", //附加值
            dealNumber: this.$route.query.dealNumber
              ? this.$route.query.dealNumber
              : "", //订单编号
            // dealStatus: 8, //交易状态
            // payMethod: 11, //支付方式
            // payStatus: 1, //支付状态
            totalDealNumber: this.$route.query.totalDealNumber
              ? this.$route.query.totalDealNumber
              : "", //总订单号
            // transactionalNumber: "", //银行流水号
            userToken: token,
            totalAmount: Number(this.totalAmount),
            // description: this.goodsName,
          },
        };
        Android.callAndroid(JSON.stringify(object));
        return;
      }

      // this.BonusIntegral(); //测试 赠送积分接口
      // //测试 直接跳过付款
      // await this.$API.order.updateOrderDealByDealNumber({
      //   object: {
      //     attach: "", //附加值
      //     dealNumber: "", //订单编号
      //     dealStatus: 8, //交易状态
      //     payMethod: 11, //支付方式
      //     payStatus: 1, //支付状态
      //     totalDealNumber: this.$route.query.totalDealNumber, //总订单号
      //     transactionalNumber: "", //银行流水号
      //   },
      // });
      // this.$router.replace({
      //   path: "/confirmAnOrder/completionOfPayment",
      //   query: {
      //     dealNumber: this.$route.query.dealNumber
      //       ? this.$route.query.dealNumber
      //       : undefined,
      //     totalDealNumber: this.$route.query.totalDealNumber
      //       ? this.$route.query.totalDealNumber
      //       : undefined,
      //   },
      // });
      // //
      // return;

      await this.$API.pay
        .WchtAppPcdrPyOrder({
          object: {
            bankType: 2, //2广州银行 3江苏银行
            cmdtyNm: this.goodsName, //商品名
            dealNumber: this.$route.query.dealNumber
              ? this.$route.query.dealNumber
              : "", //子订单号
            ipayAmt: Math.round(this.totalAmount * 100), //金额 分
            // ipayAmt:Math.round( this.totalAmount * 100), //金额 分
            totalDealNumber: this.$route.query.totalDealNumber
              ? this.$route.query.totalDealNumber
              : "", //总订单号
            usrOpenId: getOpenId() ? getOpenId() : "",
            //getOpenId()
          },
        })

        .then((res) => {
          console.log(res);
          // window.location.href = res.data.data.payUrl;
          // return
          //广银
          if ((res.data.data.unnpyRetMsg = "SUCCESS")) {
            this.info = JSON.parse(res.data.data.pymtRsltInf);
            this.arouseWeChatPayment();
          }
        })
        .catch((err) => {
          this.Toast.fail("生成付款超时");
          console.log(err);
        });
    },
    // async balancePay() {
    //   await this.$API.pay
    //     .balancePay({
    //       object: {
    //         amount: 1,
    //         balanceAcctId: "2007641883137270825",
    //         bankType: 2,
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       window.location.href = res.data.data.codeURL;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    //唤起微信支付
    arouseWeChatPayment(info) {
      const that = this;

      function onBridgeReady() {
        console.log(that.info);
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: that.info.appid ? that.info.appid : that.info.appId, //公众号ID，由商户传入
            timeStamp: that.info.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: that.info.nonceStr, //随机串
            package: that.info.packages
              ? that.info.packages
              : that.info.package,
            signType: that.info.signType, //微信签名方式：
            paySign: that.info.paySign, //微信签名
          },
          async function (res) {
            console.log(res.err_msg);
            console.log(res);
            // that.Toast.fail(res.err_msg);
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              await that.task();
              if (
                that.bank.find((item) => {
                  return item.bankId === "06D7C96C4ACE485DBDE187A9A0A6FD7F";
                })
              ) {
                await that.BonusIntegral();
              } else {
                // 微信支付跳转
                that.$router.replace({
                  path: "/confirmAnOrder/completionOfPayment",
                  query: {
                    dealNumber: that.$route.query.dealNumber
                      ? that.$route.query.dealNumber
                      : undefined,
                    totalDealNumber: that.$route.query.totalDealNumber
                      ? that.$route.query.totalDealNumber
                      : undefined,
                  },
                });
              }
              // that.$router.replace("/CompletionOfPayment");
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
              that.Toast.fail("用户取消支付");
            }
          }
        );
      }
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    },
    //消费获取积分
    async BonusIntegral() {
      await this.$API.user
        .BonusIntegral({
          object: {
            dealNumber: this.$route.query.totalDealNumber
              ? this.$route.query.totalDealNumber
              : this.$route.query.dealNumber,
            // referenceAmount: this.referenceAmount,
            actualPayment:
              Math.round(this.amountOfRefundablePoints * 100) / 100,
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.code !== "SUCCESS") {
            console.log(res.data.msg);
            this.Toast.fail(res.data.msg);
          }

          this.$router.replace({
            path: "/confirmAnOrder/completionOfPayment",
            query: {
              dealNumber: this.$route.query.dealNumber
                ? this.$route.query.dealNumber
                : undefined,
              totalDealNumber: this.$route.query.totalDealNumber
                ? this.$route.query.totalDealNumber
                : undefined,
            },
          });
        });
    },
    async test() {
      const data = await this.$API.order.finishOrderDealByDealNumber({
        object: {
          dealNumber: this.$route.query.dealNumber
            ? this.$route.query.dealNumber
            : "",
          dealStatus: 8,
          payMethod: 4,
          payStatus: 1,
          totalDealNumber: this.$route.query.totalDealNumber
            ? this.$route.query.totalDealNumber
            : "",
        },
      });
      console.log(data);
    },
    // app调取小程序支付回调
    async callJS(val) {
      let info = JSON.parse(val);

      // let info = {
      //   data: {
      //     dealNumber: "DD202304240015100371",
      //     totalDealNumber: "",
      //     paySuccess: false,
      //   },
      //   ext: {},
      //   type: 100,
      // };

      if (info.data.paySuccess) {
        await this.task();
        if (
          this.bank.find((item) => {
            return item.bankId === "06D7C96C4ACE485DBDE187A9A0A6FD7F";
          })
        ) {
          await this.BonusIntegral();
        } else {
          // 微信支付跳转
          this.$router.replace({
            path: "/confirmAnOrder/completionOfPayment",
            query: {
              dealNumber: this.$route.query.dealNumber
                ? this.$route.query.dealNumber
                : undefined,
              totalDealNumber: this.$route.query.totalDealNumber
                ? this.$route.query.totalDealNumber
                : undefined,
            },
          });
        }
      } else {
        that.Toast.fail("用户取消支付");
      }
    },
    // 完成任务
    async task() {
      if (!this.taskList.length)
        await this.$store.dispatch("user/getTaskQueryList");
      let dailyOrderTask = this.taskList[1].list[1];
      if (dailyOrderTask.times < dailyOrderTask.upLimit)
        await this.$store.dispatch("user/taskFinished", dailyOrderTask.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.pay {
  background: #f5f5f5;
  position: absolute;
  top: 46px;
  width: 100%;
  bottom: 0;
  padding-top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > strong {
    font-size: 24px;
    line-height: 34px;
    color: #fb4e10;
    span {
      font-size: 12px;
    }
  }
  > p {
    background: #fb4e10;
    color: #fff;
    line-height: 16px;
    padding: 4px 14px;
    font-size: 12px;
    border-radius: 24px;
  }
  ::v-deep .van-radio-group {
    width: 345px;
    margin-top: 38px;

    border-radius: 5px;
    .van-cell {
      padding: 12px 20px;
      .van-cell__title {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          margin-right: 24px;
        }
        span {
          font-size: 16px;
          color: #333333;
        }
      }
    }
    [class*="van-hairline"]::after {
      display: none;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    ::v-deep .van-button {
      width: 345px;
      .van-button__text {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
